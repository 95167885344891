<template>
    <div class="fullscreen row">
        <div class="col flex items-center justify-center">
            <div class="q-gutter-sm form-container">
                <router-link to="/"><img alt="Calcola la superficie commerciale con un click" src="images/logo_areaplan.png" style="height: 100px; max-width: 80vw; object-fit: contain"/></router-link>
                <h1 class="text-accent text-weight-bolder text-h4">{{ capital($t('auth.login')) }}</h1>
                <q-form @submit="submit">
                  <q-input
                          color="light-gray"
                          label-color="light-gray"
                          square
                          outlined
                          v-model="formData.email"
                          :label="$t('generals.email')"
                          type="email"
                          :rules="[val => !!val || $t('validation.required', { field: $t('generals.email')}), val => isValidEmail(val)]"
                          lazy-rules
                  />
                  <q-input
                          color="light-gray"
                          label-color="light-gray"
                          square
                          outlined
                          v-model="formData.password"
                          :label="$t('generals.password')"
                          :type="hidePassword ? 'password' : 'text'"
                          :rules="[
                              val => !!val || $t('validation.required', { field: $t('generals.password')}),
                              val => val.length >= 8 || $t('validation.min', { field: $t('generals.password'), size: 8})
                          ]"
                          lazy-rules
                  >
                      <template v-slot:append>
                          <q-icon
                                  :name="hidePassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                  class="cursor-pointer"
                                  @click="hidePassword = !hidePassword"
                          />
                      </template>
                  </q-input>
                  <div class="text-red-4 text-bold">ATTENZIONE, il software funziona solo da DESKTOP</div>
                  <div class="q-mt-md text-size-sm text-caption">
                      <q-btn size="lg" color="accent" class="q-px-lg q-py-xs text-weight-bolder" @click="submit">{{ capital($t('auth.login')) }}</q-btn>
                      <div class="q-mt-md text-light-gray">
                          {{ $t('auth.dont_have_account') }}
                          <a href="/auth/signup" class="text-accent">{{ $t('auth.signup') }}</a>
                      </div>
                      <div class="q-mt-md text-light-gray">
                          <a href="/auth/password-reset-request" class="text-accent">{{ $t('auth.cant_login') }}</a>
                      </div>
                  </div>
                </q-form>
            </div>
        </div>
    </div>
</template>

<script>
import { capitalize } from 'lodash'
import User from 'src/store/models/User'

export default {
  name: 'Login',

  data () {
    return {
      hidePassword: true,
      hidePasswordConfirm: true,
      formData: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    isValidEmail (email) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
      return emailPattern.test(email) || this.$t('validation.regex', { field: this.$t('generals.email') })
    },
    submit () {
      this.$q.loading.show()
      User.$login(this.formData).then((response) => {
        this.$router.push({ name: 'projects' })
        this.$q.loading.hide()
      }).catch(() => {
        this.$q.loading.hide()
      })
    },
    capital (a) {
      return capitalize(a)
    }
  }
}
</script>

<style scoped lang="scss">
    .form-container {
        max-height: 100vh;
        overflow: auto;
    }
    .app-name {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    body.screen--xl,body.screen--lg {
        .form-container {
            width: 30%;
        }
    }
</style>
